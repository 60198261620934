import React from 'react';
import logo from '../assets/images/H-Logo.png'; // Update this path according to your project structure

const TermsOfService = () => {
  return (
    <div className="bg-gray-100">
      <header className="bg-white shadow-sm">
        <nav className="container mx-auto px-6 py-4">
          <div className="flex justify-between items-center">
            <a href="/">
              <img src={logo} alt="NexProj Logo" className="h-10" />
            </a>
            <a href="/" className="text-blue-600 hover:text-blue-800 transition duration-300">Back to Home</a>
          </div>
        </nav>
      </header>

      <main className="container mx-auto px-6 py-12 max-w-4xl">
        <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">Terms of Service</h1>
        
        <div className="bg-white shadow-lg rounded-lg p-8">
          <section className="terms-section mb-8">
            <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
            <p className="text-gray-700">By accessing, browsing, or using any part of the NexProj platform (hereinafter referred to as "the Service"), you acknowledge that you have read, understood, and agree to be bound by these Terms of Service. If you do not agree to these terms, you should immediately stop using the Service. Continued use of the platform after any changes to these Terms implies your acceptance of those changes.</p>
          </section>

          <section className="terms-section mb-8">
            <h2 className="text-2xl font-semibold mb-4">2. Description of Service</h2>
            <p className="text-gray-700">NexProj offers a sophisticated, AI-driven project management platform designed specifically for industrial sectors. Our service is focused on optimizing workflows, improving productivity, and providing advanced tools for project planning, tracking, and execution. NexProj reserves the right to modify, suspend, or discontinue any aspect of the service at any time, with or without notice. This may include changes to features, data storage capacities, or other functions, and we shall not be held liable for any such modifications.</p>
          </section>

          <section className="terms-section mb-8">
            <h2 className="text-2xl font-semibold mb-4">3. User Accounts</h2>
            <p className="text-gray-700">To access certain features of the NexProj platform, you must create an account. You are responsible for maintaining the confidentiality of your account login credentials, including your password. You agree that any actions carried out under your account will be your responsibility, whether or not authorized by you. NexProj is not liable for any loss or damage arising from your failure to adequately safeguard your account details. You must notify us immediately of any suspected unauthorized use or security breach related to your account.</p>
          </section>

          <section className="terms-section mb-8">
            <h2 className="text-2xl font-semibold mb-4">4. User Content</h2>
            <p className="text-gray-700">You retain ownership of any content, data, or materials (collectively "Content") that you upload, submit, post, or otherwise display on or through the Service. By submitting Content to NexProj, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, adapt, distribute, and publicly display your Content as necessary to provide and improve the Service. While NexProj does not claim ownership of your Content, we reserve the right to remove or refuse to distribute any Content that we believe violates these Terms or applicable laws.</p>
          </section>

          <section className="terms-section mb-8">
            <h2 className="text-2xl font-semibold mb-4">5. Intellectual Property</h2>
            <p className="text-gray-700">The NexProj platform, including its original software, design, architecture, features, trademarks, and all other intellectual property (collectively "IP"), is owned by NexProj. All elements of the Service are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws. You agree not to reproduce, duplicate, copy, sell, or exploit any portion of the Service or its IP without express written permission from NexProj. Unauthorized use of our IP may result in legal action.</p>
          </section>

          <section className="terms-section mb-8">
            <h2 className="text-2xl font-semibold mb-4">6. Limitation of Liability</h2>
            <p className="text-gray-700">NexProj and its affiliates, directors, employees, and agents shall not be held responsible for any direct, indirect, incidental, special, consequential, or punitive damages arising from your use of or inability to use the Service. This includes, but is not limited to, damages for lost profits, data loss, service interruptions, or any other commercial damages or losses, even if NexProj had been advised of the possibility of such damages. Your use of the platform is entirely at your own risk.</p>
          </section>

          <section className="terms-section mb-8">
            <h2 className="text-2xl font-semibold mb-4">7. Governing Law</h2>
            <p className="text-gray-700">These Terms of Service and any disputes related to or arising from the use of the NexProj platform shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles. You agree to submit to the personal and exclusive jurisdiction of the courts located within [Your Jurisdiction] for the resolution of any legal matters or disputes.</p>
          </section>

          <section className="terms-section mb-8">
            <h2 className="text-2xl font-semibold mb-4">8. Changes to Terms</h2>
            <p className="text-gray-700">NexProj reserves the right to modify, update, or replace these Terms of Service at any time, at its sole discretion. In the event of any significant changes to these Terms, we will provide notice to users via email or through a prominent notice on the platform. It is your responsibility to review these Terms periodically for updates. Your continued use of the Service following the posting of changes signifies your acceptance of the modified Terms. If you do not agree to any changes, you must stop using the Service immediately.</p>
          </section>

          <section className="mt-8 text-center">
            <p className="text-gray-700">Please contact <a href="mailto:support@nexproj.com" className="text-blue-600 hover:text-blue-800">support@nexproj.com</a> if you have any questions or require further clarification on these Terms.</p>
          </section>
        </div>
      </main>

      <footer className="bg-gray-800 text-white py-6 mt-12">
        <div className="container mx-auto px-6 text-center">
          <p>&copy; 2024 NexProj. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default TermsOfService;
