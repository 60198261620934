import React, { useState, useRef } from 'react';
import videoSource from '../assets/videos/2.mp4'; 
import logo from '../assets/images/H-Logo.png';
import footerLogo from '../assets/images/F-Logo.png';
import showcaseVideo from '../assets/videos/nexproj.mp4';
import { Play, Pause, Volume2, VolumeX } from 'lucide-react';

const Index = () => {
  // Video control states
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  // Video control functions
  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
    }
  };

  return (
    <>
      {/* Enhanced Video Header Section */}
      <header className="video-container relative flex items-center justify-center min-h-screen overflow-hidden">
        <video 
          autoPlay 
          loop 
          muted 
          playsInline 
          className="absolute inset-0 w-full h-full object-cover scale-105 transform hover:scale-100 transition-transform duration-700"
        >
          <source src={videoSource} type="video/mp4" />
          Your browser does not support.
        </video>
        <div className="video-overlay absolute inset-0 bg-gradient-to-b from-black/70 via-black/50 to-black/70"></div>
        <div className="content relative z-10 flex flex-col items-center justify-center text-center text-white px-4 max-w-6xl mx-auto">
          <img 
            src={logo} 
            alt="NexProj Logo" 
            className="w-40 md:w-48 h-auto mb-8 hover:scale-105 transition-transform duration-300" 
          />
          <h1 className="text-4xl md:text-6xl font-bold mb-6 leading-tight">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-teal-400">
              Revolutionary AI-Driven Project
            </span>
            <br />
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-teal-400">
              Management
            </span>
          </h1>
          <p className="text-xl md:text-2xl mb-12 max-w-3xl leading-relaxed text-gray-200">
            NexProj is bringing AI analytics, 3D visualization, and IoT integration to transform industrial project management. Coming soon to revolutionize your workflow.
          </p>
          <a 
            href="#about" 
            className="group relative inline-flex items-center px-12 py-4 rounded-full bg-gradient-to-r from-blue-500 to-blue-700 text-lg font-semibold overflow-hidden transition-all duration-300 hover:shadow-lg hover:shadow-blue-500/30"
          >
            <span className="relative z-10">Get Notified</span>
            <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-800 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></div>
          </a>
        </div>
        {/* Animated scroll indicator */}
        <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 animate-bounce">
          <div className="w-8 h-12 rounded-full border-2 border-white flex items-start justify-center p-2">
            <div className="w-1 h-3 bg-white rounded-full animate-scroll"></div>
          </div>
        </div>
      </header>

      {/* Enhanced Main Content */}
      <main className="py-20 bg-gradient-to-b from-gray-50 to-white" id="about">
        <div className="container mx-auto px-6">
          {/* About Section with Card Style */}
          <section className="mb-32 text-center">
            <h2 className="text-4xl font-bold mb-8 relative inline-block">
              About NexProj
              <div className="absolute -bottom-4 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-blue-500"></div>
            </h2>
            <div className="bg-white rounded-2xl shadow-xl p-8 md:p-12 max-w-4xl mx-auto transform hover:-translate-y-1 transition-all duration-300">
              <p className="text-center text-lg md:text-xl text-gray-700 leading-relaxed">
                NexProj is set to revolutionize project management in industries like oil and gas, construction, and manufacturing. Our platform combines cutting-edge AI analytics, immersive 3D visualization, and seamless IoT integration to provide unparalleled insights and control over your projects.
              </p>
            </div>
          </section>

          {/* Enhanced Key Features Section */}
          <section className="mb-32 text-center">
            <h2 className="text-4xl font-bold mb-16 relative inline-block">
              Key Features
              <div className="absolute -bottom-4 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-blue-500"></div>
            </h2>
            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  icon: "🤖",
                  title: "AI-driven Analytics",
                  description: "Harness the power of AI to predict outcomes and optimize resources."
                },
                {
                  icon: "📊",
                  title: "3D Visualization",
                  description: "Visualize your projects in stunning 3D with BIM integration."
                },
                {
                  icon: "📡",
                  title: "IoT Integration",
                  description: "Real-time monitoring and data collection for informed decision-making."
                }
              ].map((feature, index) => (
                <div 
                  key={index}
                  className="group bg-white rounded-2xl shadow-lg p-8 transform hover:-translate-y-2 transition-all duration-300 hover:shadow-xl"
                >
                  <div className="mb-6 transform group-hover:scale-110 transition-transform duration-300">
                    <span className="text-6xl">{feature.icon}</span>
                  </div>
                  <h3 className="text-2xl font-bold mb-4 text-gray-800">{feature.title}</h3>
                  <p className="text-gray-600 leading-relaxed">{feature.description}</p>
                </div>
              ))}
            </div>
          </section>

          {/* Video Showcase Section */}
          <section className="mb-32 text-center">
            <h2 className="text-4xl font-bold mb-8 relative inline-block">
            Transform Your Projects with NexProj
              <div className="absolute -bottom-4 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-blue-500"></div>
            </h2>
            
            <div className="bg-white rounded-2xl shadow-xl p-8 max-w-5xl mx-auto">
              <p className="text-xl text-gray-700 mb-12 max-w-3xl mx-auto leading-relaxed">
                Discover how NexProj’s AI and 3D visualizations optimize project management and empower informed decisions.
              </p>

              {/* Video Container */}
              <div className="transform transition-all duration-300 hover:scale-[1.02]">
                <div className="relative rounded-xl overflow-hidden 
                              shadow-[0_20px_50px_rgba(8,_112,_184,_0.1)] 
                              bg-gray-900 group">
                  <video 
                    ref={videoRef}
                    className="w-full h-full object-cover rounded-xl cursor-pointer"
                    playsInline
                    muted={isMuted}
                    onEnded={handleVideoEnd}
                    onClick={togglePlay}
                  >
                    <source src={showcaseVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>

                  {/* Video Controls Overlay */}
                  <div className="absolute inset-0 bg-gradient-to-t from-black/20 via-transparent to-transparent 
                                pointer-events-none rounded-xl"></div>

                  {/* Video Controls */}
                  <div className="absolute bottom-0 left-0 right-0 p-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300
                                flex items-center justify-between bg-gradient-to-t from-black/50 to-transparent">
                    {/* Play/Pause Button */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        togglePlay();
                      }}
                      className="text-white p-2 rounded-full hover:bg-white/20 transition-colors duration-300"
                    >
                      {isPlaying ? (
                        <Pause className="w-6 h-6" />
                      ) : (
                        <Play className="w-6 h-6" />
                      )}
                    </button>

                    {/* Mute Button */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleMute();
                      }}
                      className="text-white p-2 rounded-full hover:bg-white/20 transition-colors duration-300"
                    >
                      {isMuted ? (
                        <VolumeX className="w-6 h-6" />
                      ) : (
                        <Volume2 className="w-6 h-6" />
                      )}
                    </button>
                  </div>

                  {/* Large Play Button */}
                  {!isPlaying && (
                    <button
                      onClick={togglePlay}
                      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
                                bg-white/30 backdrop-blur-sm p-6 rounded-full
                                hover:bg-white/40 transition-colors duration-300"
                    >
                      <Play className="w-12 h-12 text-white" />
                    </button>
                  )}
                </div>

                {/* Caption Card */}
                <div className="relative bg-white rounded-2xl -mt-4 mx-4 p-4 shadow-lg">
                  <p className="text-gray-500 italic text-sm">
                  Explore NexProj's innovative solutions for seamless project management and cost control.
                  </p>
                </div>
              </div>
            </div>
          </section>


          {/* Newsletter Section */}
<section className="relative overflow-hidden">
  <div className="w-full py-24 px-6 bg-gradient-to-br from-blue-600 via-blue-700 to-indigo-800">
    {/* Main Content Container */}
    <div className="relative max-w-4xl mx-auto text-center z-10">
      {/* Section Content */}
      <div className="space-y-8">
        <h2 className="text-4xl md:text-5xl font-bold text-white">
          Stay Updated
        </h2>
        <p className="text-xl text-blue-100 max-w-2xl mx-auto">
          Be the first to know when NexProj launches. Sign up for our newsletter.
        </p>

        {/* Newsletter Form */}
        <form className="mt-8 max-w-md mx-auto">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="relative flex-grow">
              <input 
                type="email" 
                placeholder="Enter your email" 
                className="w-full px-6 py-4 rounded-full bg-white/10 border border-white/20 
                         text-white placeholder-blue-100/75 focus:outline-none focus:border-white/40
                         focus:bg-white/20 transition-all duration-300"
              />
            </div>

            <button 
              type="submit"
              className="inline-flex items-center justify-center px-8 py-4 rounded-full 
                       bg-white text-blue-600 font-semibold hover:bg-blue-50 
                       transition-all duration-300 group"
            >
              <span>Subscribe</span>
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-5 w-5 ml-2 group-hover:translate-x-1 transition-transform duration-300" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M14 5l7 7m0 0l-7 7m7-7H3" 
                />
              </svg>
            </button>
          </div>
        </form>

        {/* Trust Badge */}
        <p className="text-sm text-blue-100/75 mt-6">
          Join industry professionals. No spam, ever.
        </p>
      </div>

      {/* Decorative Elements */}
      <div className="absolute top-0 left-0 w-72 h-72 bg-blue-500/10 rounded-full -translate-x-1/2 -translate-y-1/2"></div>
      <div className="absolute bottom-0 right-0 w-72 h-72 bg-indigo-500/10 rounded-full translate-x-1/2 translate-y-1/2"></div>
    </div>
  </div>
</section>
        </div>
      </main>

      {/* Enhanced Footer */}
      <footer className="bg-gray-900 text-white py-12">
        <div className="container mx-auto px-6 text-center">
          <img 
            src={footerLogo} 
            alt="NexProj Logo" 
            className="h-10 mx-auto mb-8 hover:opacity-80 transition-opacity duration-300" 
          />
          
          <nav className="flex flex-wrap justify-center gap-8">
            {[
              { href: "/privacy-policy", text: "Privacy Policy" },
              { href: "/terms-of-service", text: "Terms of Service" },
             
            ].map((link, index) => (
              <a 
                key={index}
                href={link.href} 
                className="text-gray-400 hover:text-white transition-colors duration-300 relative group"
              >
                {link.text}
                <span className="absolute left-0 bottom-0 w-full h-0.5 bg-blue-500 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
              </a>
            ))}
          </nav>
          <p className="text-gray-400 mb-8"></p>
          <p className="text-gray-400 mb-8">&copy; {new Date().getFullYear()} NexProj. All rights reserved.</p>
        </div>
      </footer>
    </>
  );
};

export default Index;