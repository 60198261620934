import React from 'react';
import logo from '../assets/images/H-Logo.png'; // Make sure you update the path to your logo image

const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-100">
      <header className="bg-white shadow-sm">
        <nav className="container mx-auto px-6 py-4">
          <div className="flex justify-between items-center">
            <a href="/">
              <img src={logo} alt="NexProj Logo" className="h-10" />
            </a>
            <a href="/" className="text-blue-600 hover:text-blue-800 transition duration-300">Back to Home</a>
          </div>
        </nav>
      </header>

      <main className="container mx-auto px-6 py-12 max-w-4xl">
        <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">Privacy Policy</h1>
        
        <div className="bg-white shadow-lg rounded-lg p-8">
          <section className="policy-section mb-8">
            <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
            <p className="text-gray-700">Welcome to the Privacy Policy of NexProj ("we", "our", or "us"). This policy outlines how we collect, use, store, and protect the personal information of individuals who use our AI-driven project management platform (the "Platform"). Your privacy is important to us, and we are committed to safeguarding your data while ensuring transparency regarding how we handle it. By accessing or using our Platform, you agree to the practices described in this Privacy Policy.</p>
          </section>

          <section className="policy-section mb-8">
            <h2 className="text-2xl font-semibold mb-4">2. Information We Collect</h2>
            <p className="text-gray-700">We collect various types of information to provide you with an optimized experience when you use our Platform. The information we collect includes, but is not limited to:</p>
            <h3 className="text-xl font-semibold mt-4 mb-2">Personal Information:</h3>
            <ul className="list-disc list-inside ml-4 text-gray-700">
              <li>Your full name</li>
              <li>Email address</li>
              <li>Company or organization name</li>
              <li>Job title or role</li>
              <li>Billing and payment information, if applicable</li>
            </ul>
            <h3 className="text-xl font-semibold mt-4 mb-2">Usage Data:</h3>
            <ul className="list-disc list-inside ml-4 text-gray-700">
              <li>Your IP address</li>
              <li>Browser type and version</li>
              <li>Device type and operating system</li>
              <li>Pages you visit on the Platform</li>
              <li>The duration of your visits and your navigation patterns</li>
              <li>Log data, which may include timestamps, error reports, and crash reports</li>
            </ul>
            <h3 className="text-xl font-semibold mt-4 mb-2">Project Data:</h3>
            <ul className="list-disc list-inside ml-4 text-gray-700">
              <li>Project details, descriptions, timelines, and progress updates</li>
              <li>Team member names, roles, and related communication</li>
              <li>Files, tasks, and other project-specific content you upload</li>
            </ul>
          </section>

          <section className="policy-section mb-8">
            <h2 className="text-2xl font-semibold mb-4">3. How We Use Your Information</h2>
            <p className="text-gray-700">The data we collect is used for the following purposes:</p>
            <ul className="list-disc list-inside ml-4 text-gray-700">
              <li>Service Delivery: To provide the core functionalities of the Platform, such as enabling project collaboration, managing project timelines, and tracking tasks and progress.</li>
              <li>Platform Improvement: To analyze user interactions and usage patterns, helping us improve the performance, features, and overall user experience of our Platform.</li>
              <li>Communication: To contact you regarding your account, respond to inquiries, send you updates, and notify you of important changes to our services or policies.</li>
              <li>Personalization: To tailor your experience on the Platform, including displaying relevant content, features, and project recommendations.</li>
              <li>Security and Fraud Prevention: To monitor for and prevent fraudulent, suspicious, or unauthorized activities on your account or our Platform.</li>
              <li>Compliance: To comply with legal obligations, resolve disputes, and enforce our agreements.</li>
            </ul>
          </section>

          <section className="policy-section mb-8">
            <h2 className="text-2xl font-semibold mb-4">4. Data Security</h2>
            <p className="text-gray-700">We take your data security seriously and implement various measures to ensure that your information is protected. These include, but are not limited to:</p>
            <ul className="list-disc list-inside ml-4 text-gray-700">
              <li>Encryption: Data transmitted to and from our Platform is encrypted using secure protocols (e.g., SSL/TLS).</li>
              <li>Access Controls: We limit access to personal data to authorized employees, contractors, and third-party service providers who need the information to provide our services.</li>
              <li>Data Anonymization: Where possible, we anonymize or pseudonymize data to protect your privacy.</li>
              <li>Regular Audits: We regularly review our data security practices and update them to meet industry standards.</li>
            </ul>
            <p className="text-gray-700 mt-4">Despite our best efforts, no method of transmission over the Internet or electronic storage is entirely secure. While we strive to protect your personal data, we cannot guarantee its absolute security. In the event of a data breach, we will take appropriate steps to notify affected users and authorities as required by law.</p>
          </section>

          <section className="policy-section mb-8">
            <h2 className="text-2xl font-semibold mb-4">5. Your Rights</h2>
            <p className="text-gray-700">You have the following rights concerning your personal information:</p>
            <ul className="list-disc list-inside ml-4 text-gray-700">
              <li>Access: You may request a copy of the personal information we hold about you.</li>
              <li>Correction: You may request that we correct any inaccurate or incomplete personal information.</li>
              <li>Deletion: You may request that we delete your personal information, subject to certain exceptions (e.g., data needed for legal or security purposes).</li>
              <li>Data Portability: You have the right to request a structured, commonly used, and machine-readable format of your personal information.</li>
              <li>Withdrawal of Consent: Where we rely on your consent to process your data, you have the right to withdraw it at any time. However, this may limit your ability to use certain features of the Platform.</li>
            </ul>
            <p className="text-gray-700 mt-4">If you wish to exercise any of these rights or have questions regarding your personal data, please contact us using the details provided in Section 8.</p>
          </section>

          <section className="policy-section mb-8">
            <h2 className="text-2xl font-semibold mb-4">6. Third-Party Services and Data Sharing</h2>
            <p className="text-gray-700">We may share your data with trusted third-party service providers to assist in operating our Platform or providing specific services, such as hosting providers, analytics services, and payment processors. These third parties are obligated to maintain the confidentiality and security of your data and only use it for the purposes we specify.</p>
            <p className="text-gray-700 mt-4">We do not sell or rent your personal information to third parties for marketing purposes. However, we may disclose information in the following circumstances:</p>
            <ul className="list-disc list-inside ml-4 text-gray-700">
              <li>To comply with legal obligations or respond to lawful requests from public authorities.</li>
              <li>To protect the rights, privacy, safety, or property of NexProj, our users, or the public.</li>
              <li>In connection with a merger, acquisition, or sale of our business or assets, in which case we will notify you of any such change in ownership.</li>
            </ul>
          </section>

          <section className="policy-section mb-8">
            <h2 className="text-2xl font-semibold mb-4">7. Changes to This Privacy Policy</h2>
            <p className="text-gray-700">We reserve the right to modify or update this Privacy Policy at any time. Any changes will be posted on this page, along with the effective date of the updated policy. We encourage you to periodically review this page to stay informed about how we are protecting your data. If the changes are significant, we may provide additional notice (such as via email or through notifications on the Platform).</p>
            <p className="text-gray-700 mt-4">Your continued use of our Platform following any changes to this Privacy Policy signifies your acceptance of those changes.</p>
          </section>

          <section className="policy-section mb-8">
            <h2 className="text-2xl font-semibold mb-4">8. Contact Us</h2>
            <p className="text-gray-700">If you have any questions, concerns, or requests regarding this Privacy Policy or how we handle your personal data, please contact us at:</p>
            <p className="text-gray-700 mt-2">Email: <a href="mailto:privacy@nexproj.com" className="text-blue-600 hover:text-blue-800">privacy@nexproj.com</a></p>
            <p className="text-gray-700 mt-4">We are here to help and will respond to your inquiries promptly.</p>
          </section>
        </div>
      </main>

      <footer className="bg-gray-800 text-white py-6 mt-12">
        <div className="container mx-auto px-6 text-center">
          <p>&copy; 2024 NexProj. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
